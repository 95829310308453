import React, { useState } from 'react'
import Parser from 'html-react-parser';
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

function MServiceHomePartModal(props) {
    const {
        asks,
        title,
        name,
        fc_DESCRIPCION,
        fi_PRECIO_PLAN,
        fc_ETIQUETA_PERIODO,
        Caracteristicas,
        titlestyle,
        // fc_TITULO_PROMO,
        // caracteristicasPromocion
        // fi_servicio,
        symbolPrice,
    } = props

    const [questions, setQuestions] = useState(asks)
    const [activePromo, setActivePromo] = useState(false)

    useEffect(() => {
        // console.log(asks)
        // console.log(questions)
    }, [asks, questions])

    const handleChangeOpenQuestion = (item, index) => {
        setQuestions([...questions].map(object => {
            if (object.fc_TITULO === item.fc_TITULO) {
                return {
                    ...object,
                    open: !object.open
                }
            }
            else return object;
        }))
    }
    const stylesQues = {
        open: {
            display: "block",
            // maxHeight: "500px",
            transition: "all 0.25s ease-in",
        },
        close: {
            // display: "none",
            maxHeight: 0,
            transition: "all 0.25s ease-out",
            overflow: "hidden",
            paddingTop: 0,
            paddingBottom: 0,
        }
    }
    const _renderAsks = (items) => (
        items ?
            items.map((item, index) => (
                <dl className="genAccItem" key={uuid()} onClick={() => { handleChangeOpenQuestion(item, index) }}>
                    {console.log("_rendersAsks:", item)}
                    <dt className={`genAccTitle ${item.open ? 'active' : ''}`}>{item.fc_TITULO}</dt>
                    <dd className="genAccCont" style={item.open ? stylesQues.open : stylesQues.close}>
                        <p>{Parser(item.fc_DESCRIPCION)}</p>
                    </dd>
                </dl>
            ))
            : <>
                <div>Cargando...</div>
            </>
    )
    const getActivePromo = function () {
        Caracteristicas.map((caract, index) => (
            caract.fc_VALOR.includes("claroVideo") || caract.fc_VALOR.includes("claroMusica") || caract.fc_VALOR.includes("spotify") || caract.fc_VALOR.includes("netflix")
                ?
                <>
                    {
                        console.log(caract.fc_VALOR)
                    }
                    {
                        setActivePromo(true)
                    }
                </>
                : null
        ))
        console.log("activePromo", activePromo)
    }
    useEffect(() => {
        getActivePromo()
        // eslint-disable-next-line
    })
    
    return (

        <div className={"modalClaro mServiceHome " + (titlestyle ? titlestyle : 'amarillo')}>
            <div className="mServiceHomeMain">
                <div className="mServiceHomeCont">
                    <div className="mServiceHomeHeader">
                        {
                            title ?
                                <>
                                    <div className="mServiceHomePlan">
                                        <p>{title}</p>
                                    </div>
                                </>
                                : null
                        }
                        <div className="mServiceHomeInfo">
                            <div className="mServiceHomeSpeed">
                                <p>{name}</p>
                            </div>
                            <div className="mServiceHomeBuild">
                                {
                                    fc_DESCRIPCION ?
                                        <p>
                                            {fc_DESCRIPCION}
                                        </p>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mServiceHomeBody">
                        <div className="mServiceHomeFeatures">
                            {
                                Caracteristicas.map((caract, index) => (
                                    <div key={uuid()} className="mServiceHomeFeature">
                                        <div className="mServiceHomeFeatureTitle">
                                            {
                                                caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                    ? <p>{caract.fc_TITULO} {caract.fc_TITULO.includes(caract.fc_DESCRIPCION) ? "" : caract.fc_DESCRIPCION}:</p>
                                                    : null
                                            }
                                            {/* <p>Internet:</p> */}
                                            {
                                                caract.fc_CLASE_ICONO && !caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                    ?
                                                    <i className={caract.fc_CLASE_ICONO}></i>
                                                    : null
                                            }
                                            {
                                                caract.fc_DESCRIPCION.includes("Redes Sociales") || caract.fc_VALOR.includes("logo")
                                                    ? null
                                                    :
                                                    <p>{caract.fc_TITULO}</p>

                                            }
                                        </div>
                                        {
                                            caract.fc_VALOR.includes("logo")
                                                ? null
                                                // <div className="mServiceHomeFeatureItem">
                                                //     <div className="mServiceHomeFeatureItemTitle">
                                                //         <p className={`${caract.fc_VALOR}`}>{caract.fc_VALOR}</p>
                                                //     </div>
                                                //     <div className="mServiceHomeFeatureItemVal">
                                                //         <p>{caract.fc_TITULO}</p>
                                                //     </div>
                                                // </div>
                                                :
                                                <div className="mServiceHomeFeatureItem">
                                                    {/* {
                                                        caract.fc_CLASE_ICONO
                                                            ?
                                                            <i className={caract.fc_CLASE_ICONO}></i>
                                                            : null
                                                    }
                                                    {
                                                        caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                            ? null
                                                            :
                                                            <div className="mServiceHomeFeatureItemTitle">
                                                                <p>{caract.fc_TITULO}</p>
                                                            </div>
                                                    } */}
                                                    {
                                                        caract.fc_DESCRIPCION.includes("Redes Sociales")
                                                            ?
                                                            <div className="mServiceHomeFeatureItemRS">
                                                                <ul>
                                                                    {
                                                                        caract.fc_VALOR.split("|").map((icoRed, index) => (
                                                                            <li key={uuid()}>
                                                                                {/* <a href="#!"> */}
                                                                                    <i className={'ico ' + (icoRed === 'ico-instagram-s' || icoRed === 'ico-snapchat-s' || icoRed === 'ico-telegram-s'
                                                                                        || icoRed === 'ico-tiktok-s' || icoRed === 'ico-twitter-s' || icoRed === 'ico-waze-s'
                                                                                        || icoRed === 'ico-whatsapp-s' || icoRed === 'ico-youtube-s'
                                                                                        ? icoRed.replace('ico-', 'ico-c-') : icoRed) } />
                                                                                    {/* <i className={'ico ' + icoRed } /> */}
                                                                                {/* </a> */}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            <div className="mServiceHomeFeatureItemVal">
                                                                <p>{caract.fc_VALOR}</p>
                                                            </div>
                                                    }
                                                </div>
                                        }
                                        {/* <div className="mServiceHomeFeatureItem">
                                            <i className="ico-arrow-down-circle"></i>
                                            <div className="mServiceHomeFeatureItemTitle">
                                                <p>Velocidad de bajada</p>
                                            </div>
                                            <div className="mServiceHomeFeatureItemVal">
                                                <p>2 Mbps</p>
                                            </div>
                                        </div> */}
                                    </div>
                                ))
                            }
                            {/* <div className="mServiceHomeFeature">
                                <div className="mServiceHomeFeatureTitle">
                                    <p>Internet:</p>
                                </div>
                                <div className="mServiceHomeFeatureItem"><i className="ico-arrow-up-circle"></i>
                                    <div className="mServiceHomeFeatureItemTitle">
                                        <p>Velocidad de subida</p>
                                    </div>
                                    <div className="mServiceHomeFeatureItemVal">
                                        <p>15 Mbps</p>
                                    </div>
                                </div>
                                <div className="mServiceHomeFeatureItem"><i className="ico-arrow-down-circle"></i>
                                    <div className="mServiceHomeFeatureItemTitle">
                                        <p>Velocidad de bajada</p>
                                    </div>
                                    <div className="mServiceHomeFeatureItemVal">
                                        <p>2 Mbps</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mServiceHomeFeature">
                                <div className="mServiceHomeFeatureTitle">
                                    <p>Televisión:</p>
                                </div>
                                <div className="mServiceHomeFeatureItem">
                                    <div className="mServiceHomeFeatureItemTitle">
                                        <p>Canales HD:</p>
                                    </div>
                                    <div className="mServiceHomeFeatureItemVal">
                                        <p>54</p>
                                    </div>
                                </div>
                                <div className="mServiceHomeFeatureItem">
                                    <div className="mServiceHomeFeatureItemTitle">
                                        <p>Canales Estándar:</p>
                                    </div>
                                    <div className="mServiceHomeFeatureItemVal">
                                        <p>93</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mServiceHomeFeature">
                                <div className="mServiceHomeFeatureTitle">
                                    <p>Telefónia:</p>
                                </div>
                                <div className="mServiceHomeFeatureItem"><i className="ico-phone"></i>
                                    <div className="mServiceHomeFeatureItemTitle">
                                        <p>Llamadas locales ilimitadas</p>
                                    </div>
                                </div>
                            </div>
                             
                            <div className="mServiceHomeFeature">
                                <div className="mServiceHomeFeatureTitle">
                                    <p>Redes Sociales:</p>
                                </div>
                                <div className="mServiceHomeFeatureItem">
                                    <div className="mServiceHomeFeatureItemRS">
                                        <ul>
                                            <li><a href="#!"><i className="ico ico-facebook-messenger-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-facebook-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-google-plus-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-instagram-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-linkedin-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-pinterest-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-skype-s"></i></a></li>
                                            <li><a href="#!"><i className="ico ico-snapchat-s"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                        <div className="mServiceHomeAmount">
                            {
                                activePromo
                                    ?
                                    <div key={uuid()} className="mServiceHomeProm">
                                        <p>Promoción Online Incluye:</p>
                                        <div className="mServiceHomePromGal">
                                            {
                                                Caracteristicas.map((caract, index) => (

                                                    caract.fc_VALOR.includes("claroVideo") ?
                                                        <picture key={uuid()}>
                                                            <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-video-b.webp" type="image/webp" />
                                                            <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-video-b.png" type="image/png" />
                                                            <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-video-b.webp" type="image/webp" />
                                                            <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-video-b.png" type="image/png" />
                                                            <img className="lazyload" data-src="/portal/hn/recursos_tema/assets/img/logo-claro-video-b.png" alt="Claro video" width="115" height="22" />
                                                        </picture>
                                                        :

                                                        caract.fc_VALOR.includes("claroMusica") ?
                                                            <picture key={uuid()}>
                                                                <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-musica-b.webp" type="image/webp" />
                                                                <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-musica-b.png" type="image/png" />
                                                                <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-musica-b.webp" type="image/webp" />
                                                                <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/logo-claro-musica-b.png" type="image/png" />
                                                                <img className="lazyload" data-src="/portal/hn/recursos_tema/assets/img/logo-claro-musica-b.png" alt="Claro musica" width="115" height="22" />
                                                            </picture>
                                                            :
                                                            caract.fc_VALOR.includes("spotify") ?
                                                                <picture key={uuid()}>
                                                                    <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-spotify.webp" type="image/webp" />
                                                                    <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-spotify.png" type="image/png" />
                                                                    <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-spotify.webp" type="image/webp" />
                                                                    <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-spotify.png" type="image/png" />
                                                                    <img className="lazyload" data-src="/portal/hn/recursos_tema/assets/img/img-logo-spotify.png" alt="Spotify" width="115" height="22" />
                                                                </picture>
                                                                :
                                                                caract.fc_VALOR.includes("netflix") ?
                                                                    <picture key={uuid()}>
                                                                        <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-netflix.webp" type="image/webp" />
                                                                        <source media="(max-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-netflix.png" type="image/png" />
                                                                        <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-netflix.webp" type="image/webp" />
                                                                        <source media="(min-width: 640px)" data-srcset="/portal/hn/recursos_tema/assets/img/img-logo-netflix.png" type="image/png" />
                                                                        <img className="lazyload" data-src="/portal/hn/recursos_tema/assets/img/img-logo-netflix.png" alt="Netflix" width="115" height="22" />
                                                                    </picture>
                                                                    :
                                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="mServiceHomeAmountCont mServiceHomeAmountCont2">
                                <div className="mServiceHomeAmountText">
                                    {
                                        fc_ETIQUETA_PERIODO
                                            ?
                                            <p>{fc_ETIQUETA_PERIODO}</p>
                                            : null
                                    }
                                </div>
                                {
                                    fi_PRECIO_PLAN
                                        ?
                                        < div className="mServiceHomePrices">
                                            <div className="symbol">
                                                <p>{symbolPrice}</p>
                                            </div>
                                            <div className="mServiceHomePrice">
                                                <p>{fi_PRECIO_PLAN}</p>
                                            </div>
                                            <div className="decimal decimal2">
                                                {/* <p>.00</p> */}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mServiceHomeFoot">
                    <div className="genAccordion">
                        {_renderAsks(questions)}
                    </div>
                </div>
            </div>
        </div >
    )
}

MServiceHomePartModal.defaultProps = {
    asks: [
        {
            fc_TITULO: '¿Qué hago si mi servicio de internet esta fallando? 1',
            fc_DESCRIPCION: 'Sed egestas magna sit amet enim rhoncus, quis venenatis elit hendrerit. Nullam ornare est vitae sodales congue. Aliquam id nisl a ex consequat aliquet.',
            open: false,
        },
        {
            fc_TITULO: '¿Qué hago si mi servicio de internet esta fallando? 2',
            fc_DESCRIPCION: 'Sed egestas magna sit amet enim rhoncus, quis venenatis elit hendrerit. Nullam ornare est vitae sodales congue. Aliquam id nisl a ex consequat aliquet.',
            open: false,
        },
        {
            fc_TITULO: '¿Qué hago si mi servicio de internet esta fallando? 3',
            fc_DESCRIPCION: 'Sed egestas magna sit amet enim rhoncus, quis venenatis elit hendrerit. Nullam ornare est vitae sodales congue. Aliquam id nisl a ex consequat aliquet.',
            open: false,
        },
        {
            fc_TITULO: '¿Qué hago si mi servicio de internet esta fallando? 4',
            fc_DESCRIPCION: 'Sed egestas magna sit amet enim rhoncus, quis venenatis elit hendrerit. Nullam ornare est vitae sodales congue. Aliquam id nisl a ex consequat aliquet.',
            open: false,
        }
    ]
}
MServiceHomePartModal.propTypes = {
    asks: PropTypes.array,
}

export default MServiceHomePartModal
