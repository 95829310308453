import React from 'react'
import PropTypes from 'prop-types'

// Components
// import HeaderPeopleOrg from 'Components/Organisms/Personas/Header'
// import FooterPeopleOrg from 'Components/Organisms/Personas/Footer'
// import C23PeopleOrg from 'Components/Organisms/Personas/C23'
// import C25PeopleOrg from 'Components/Organisms/Personas/C25'
// import C08PeopleOrg from './../../../Components/Organisms/Personas/C08'
import C08V3PeopleOrg from './../../../Components/Organisms/Personas/C08/v3'

function TituloModulo(props){
    const {
        titulo,
    } = props
    return (
        <section style={{padding: 0, backgroundColor: '#999'}}>
            <div className="container" style={{position: 'relative'}} > 
                <h1 style={{fontSize: '20px', fontWeight: '600', padding: '5px 10px', color: '#fff', zIndex: 1}}>
                    {titulo}
                </h1>
            </div>
        </section>
    )
}

TituloModulo.protoType = {
    titile: PropTypes.string,
}

function HomePeopleContainerUi(props) {
    // const {
    //     dataCompare
    // } = props

    return (
        <>
           
            {/* <HeaderPeopleOrg /> */}

            {/* <TituloModulo 
                titulo='Personas - C08'
            /> */}
            {/* <C08PeopleOrg /> */}
            <C08V3PeopleOrg />

            {/* <TituloModulo 
                titulo='Personas - C23'
            />
            <C23PeopleOrg />

            <TituloModulo 
                titulo='Personas - C25'
            />
            <C25PeopleOrg
                dataCompare={dataCompare}
            /> */}

            {/* <FooterPeopleOrg /> */}
        </>
    )
}

// HomePeopleContainerUi.propTypes = {

// }

export default HomePeopleContainerUi
